<template>
    <div class="login">
        <div class="bgImg"></div>
        <div class="logoWrap">
            <img src="@/assets/img/exhLogo.svg" alt="">
            <h3>Hold an OnlineExhibitions with Expo Now<br>Different scenes, different experience<br>Get closer to each other !</h3>
        </div>
        <div class="loginWrap">
            <div class="banner"></div>
            <!-- <div class="navbar">
                <h4 :class="{active:pageType=='visit'}" class="btn" @click="switchLoginType(0)">Visit the Exhibition</h4>
                <h4 :class="{active:pageType=='hold'}" class="btn" @click="switchLoginType(1)">Hold an Exhibition</h4>
            </div> -->
            <h4>Exhibitor login</h4>
            <div class="inputWrap-out" v-if="readyAction>=2">
                <div class="inputWrap" v-if="readyAction==2">
                    <label for="account">Tax ID</label>
                    <input type="text" v-on:keyup.enter="confirmAction" v-model="form.account" id="account" placeholder="Your account number">
                </div>
                <div class="inputWrap" v-if="readyAction==2">
                    <label for="password">Password</label>
                    <input type="password" v-on:keyup.enter="confirmAction" v-model="form.password" id="password" placeholder="Your password">
                </div>
                <!-- -->
                <div class="inputWrap" v-if="readyAction==3 || readyAction==7">
                    <label for="nickname">Enter a name for yourself</label>
                    <input type="text" v-model="form.nickname" id="nickname" placeholder="Enter your name">
                </div>
                <!-- -->
                <div class="inputWrap" v-if="readyAction==4">
                    <label for="email">Email</label>
                    <input type="email" v-model="form.email" id="email" placeholder="Enter your email">
                </div>
                <div class="inputWrap" v-if="readyAction==4">
                    <label for="account">Account</label>
                    <input type="text" v-model="form.account" id="account" placeholder="Enter your account number">
                </div>
                <!-- -->
                <div class="inputWrap" v-if="readyAction==5">
                    <label for="password">Password</label>
                    <input type="password" v-model="form.password" id="password" placeholder="Enter your password">
                </div>
                <div class="inputWrap" v-if="readyAction==5">
                    <label for="password2">Confirm password</label>
                    <input type="password" v-model="form.password2" id="password2" placeholder="Enter your password again">
                </div>
            </div>
            <div class="btnWrap" v-if="readyAction==1" :style="[readyAction==1?initStyle:{}]">
                <button class="submit" @click="readyAction=2" :style="[readyAction==1?{width:'100%',marginBottom:'9%'}:{}]">Login</button>
                <button class="cancel" @click="signupAccount" :style="[readyAction==1?{width:'100%'}:{}]">Signup</button>
            </div>
            <div class="btnWrap" v-if="readyAction>=2">
                <button class="cancel" @click="readyAction=1">Cancel</button>
                <button class="submit" @click="confirmAction">Confirm</button>
                <!-- confirmAction 用來判斷接下來行為 >> 確認註冊或登入 -->
            </div>
            <div class="info-wrap">
                <!-- <p>Forgot account or password</p> -->
                <p>If you have any questions, please contact us !</p>
                <p class="phone"><span></span>04-26997997</p>
            </div>
        </div>
        <div class="registerAlert" v-if="successRegister">
                <div class="container">
                    <img @click="successRegister=false" src="@/assets/img/icon/cross.svg" alt="">
                    <h4>Registration Success !</h4>
                    <p>* Please wait for us to open your account, please<br>check the notification letter on your mailbox</p>
                    <div class="btnWrap">
                        <button class="submit" @click="successRegister=false">OK</button>
                    </div>
                </div>
        </div>
    </div>
</template>
<script>
import {login, userSignup} from '@/api';
import {mapActions } from "vuex";
// import { LayerSceneComponent } from '@babylonjs/core';

export default {
    name:'login',
    data(){
        return{
            readyAction: 1,
            /*
                Action Type: 1 : 剛進入此頁
                             2 : 選擇登入(Any identity)
                             3 : 選擇User註冊

                             4 : 註冊User階段一
                             5 : 註冊User階段二

                             7: 選擇遊客登入
            */
            pageType: 'hold',
            form:{
                nickname:'',
                account:'',
                accountLogin:'',
                passwordLogin:'',
                password:'',
                password2:'',
                email:'',
            },
            initStyle:{
                flexWrap:'wrap',
                marginBottom: '35%'
            },
            successRegister:false
        }
    },
    mounted(){
        if(this.$route.query.login=='close')
            this.successRegister = true

        if (localStorage.getItem("token")&&localStorage.getItem('type')=='seller') {
            this.$router.push({name:'VendorHome'})
        }
    },
    methods:{
        ...mapActions(['setLogin']),
        async loginAccount(){
            let vm  = this
            if(this.form.account!=''&& this.form.password!=''){
                let res = await login(this.form).catch(function (error) {
                  if (error.response) {
                    if(error.response.status==422||error.response.status==404){
                        vm.$message({
                            showClose: true,
                            message:`${error.response.data.message}`,
                            type: 'warning',
                            duration: 1500,
                            onClose: ()=>{
                                vm.form.account = '';
                                vm.form.password = '';
                            }
                        });
                    }
                    return error.response.status
                  }
                });
                if(res.data){
                    // let authType = res.data.data;
                    let auth = await this.setLogin(res.data.data);
                    switch (auth) {
                        case 'admin':
                            this.loginSuccessfulMsg(()=>{
                                this.$router.push({name:'Admin'});
                                window.location.href = location.protocol+'//'+window.location.host+'/admin';
                            });
                            break;
                        case 'seller':
                            this.loginSuccessfulMsg(()=>{
                                window.location.href = location.protocol+'//'+window.location.host+'/vendor';
                            });
                            break;
                        case 'normal':
                            this.$router.push({name: 'Entrance'});
                            this.loginSuccessfulMsg();
                            break;
                        default:
                            break;
                    }
                }
            }
        },
        loginSuccessfulMsg(event){
            this.$message({
                showClose: true,
                message: `You've logged in successfully!`,
                type: 'success',
                duration: 1000,
                onClose: function(){
                    event();
                }
            });
        },
        async regiserAccount(){//給遊客註冊用
            let vm  = this
            if(this.form.account=='' || this.form.password=='' || this.form.password2=='' || this.form.nickname==''
            || this.form.email=='' || (this.form.password!=this.form.password2)){
                this.$message({
                    showClose: true,
                    message: `Please make sure you have filled correct data`,
                    type: 'error'   
                });
                return;
            }
            let userData = {
                account: this.form.account,
                nickname: this.form.nickname,
                password: this.form.password,
                email: this.form.email,
            }
            let res = await userSignup(userData)
            if(res.data.status == 201){
                this.$message({
                    showClose: true,
                    message: `You've registered successfully! entering exhibition now...`,
                    type: 'success',
                    duration: 1500,
                    onClose: ()=>{
                        this.form.account = userData.account;
                        this.form.password = userData.password;
                        this.loginAccount();
                    }
                });
            }
        },
        signupAccount(){
            if(this.pageType==='visit'){
                this.readyAction = 3;
            }
            else if(this.pageType==='hold'){
                this.$router.push({name:'VendorSignup'});
            }
        },
        confirmAction(){
            switch (this.readyAction) {
                case 2:
                    this.loginAccount();
                    this.readyAction = 1;
                    break;
                case 3:
                    this.readyAction = 4;
                    break;
                case 4:
                    this.readyAction = 5;
                    break;
                case 5:
                    this.regiserAccount();
                    this.readyAction = 1;
                    break;
                case 7:
                    this.$router.push({name: 'Entrance'});
                    localStorage.setItem('token', 'visitor');
                    localStorage.setItem("type", 'visitor');
                    this.$message({
                        showClose: true,
                        message: `Hi ${this.form.nickname}! You've entered our exhibitions with visitor identity!`,
                        type: 'success',
                        onClose: ()=>{
                        }
                    });
                    break;
                default:
                    this.readyAction = 1;
                    break;
            }
        },
        cancelAction(){

        },
    },
    computed:{

    }    
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';
@import '@/assets/scss/login.scss';
.login{
    min-width: 900px;
    display: block;
    justify-content: inherit;
    align-items: inherit;
}
.login .logoWrap{
    width: 40%;
    height: auto;
    left: 14%;
    position: absolute;
    margin-top:0;
    top: 50%;
    transform: translateY(-50%);
}
.loginWrap h4{
    margin-bottom: 4%;
}
.login .loginWrap{
    right: 4%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    justify-content: inherit;
    position: absolute;
    display: block;
    flex-direction: inherit;
    min-width: 316px;
    // min-height: 600px;
    overflow: auto;
    padding: 2.5% 3% 0%;
    height:auto;
}
.login .loginWrap .banner{
    padding-bottom: 80%;
    background-image: url('~@/assets/img/loginBanner.jpg');
    background-size: cover;
    background-position: center center;
}
.login .loginWrap .info-wrap{
    justify-content: inherit;
    height:auto;
    position: relative;
    bottom: 2%;
    transform: translateX(-50%);
    width: 100%;
    left:50%;
    margin-bottom: 7%;
}
.login .loginWrap .inputWrap-out{
    height: auto;
}
.login .loginWrap .inputWrap-out .inputWrap input{
    line-height: 2.4;
    height:auto;
    min-height: auto;
}
.login .loginWrap .btnWrap{
    margin-bottom: 16%;
    margin-top: 22px;
    height:auto;
}
.login .loginWrap .btnWrap button{
    height:auto;
    line-height: 2.7;
}
.registerAlert{
        position:fixed;
        background-color: rgba($color: #000000, $alpha: 0.5);
        top:0;
        left:0;
        right:0;
        bottom:0;
        .container{
            background-color: #fff;
            width: 30%;
            position: absolute;
            top:50%;
            left:50%;
            transform:translate(-50%,-50%);
            padding: 0.8% 0 1.8%;
            border-radius: 11px;
            border:2px #6F52ED solid;
        }
        img{
            width:3%;
            margin:0 3% 5% auto;
            display: block;
        }
        .txt{
            width: 70%;
            margin:0 auto;
        }
        h4{
            font-size:20*$font;
            color:#444444;
            margin-bottom: 6%;
        }
        p{
            font-size: 17*$font;
            line-height: 1.5;;
            margin-bottom: 7%;
        }
        h5{
            font-size: 17*$font;
            color:#444444;
            margin-bottom: 6%;
            font-weight: 400;
            margin-top: 0;
            text-align: left;
            &:nth-of-type(1){
                margin-bottom: 2%;
            }
            span{
                color:#FF4CAD;
            }
        }
        .btnWrap{
            display: flex;
            justify-content: center;
        }
        button{
            font-size: 17*$font;
            line-height: 2.3;
            width: 19%;
            border-radius: 7px;
            margin: 0 3%;
            min-width: 67px;
        }
    }
</style>